import React from "react"
import styled from "styled-components"

import SEO from "../components/SEO"
import { PageSegment, TitleAndDescription } from "../components"
import { GridOneColumn } from "../styles"

import ourStoryImg1 from "../assets/images/our-story-1.png"
import ourStoryImg2 from "../assets/images/our-story-2.png"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-bottom: 50px;
  width: 100%;

  img {
    width: 90%;
    margin: 10px;
    align-self: center;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  .TitleAndDescription {
    @media (min-width: 768px) {
      width: 100%;
      p {
        width: 90%;
      }
    }
    @media (min-width: 1024px) {
      p {
        width: 80%;
      }
    }
  }
`

const WhoAreWeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  @media (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 1/1;
    width: 100%;
  }
`
const WhatDoWeProvideBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  ul {
    padding-left: 20px;
    line-height: 2em;
    @media (min-width: 768px) {
      width: 90%;
    }
    @media (min-width: 1024px) {
      width: 80%;
    }
  }

  @media (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 2/2;
    width: 100%;
  }
`

export const OurStory = () => {
  return (
    <>
      <SEO title="Our Story" />
      <PageSegment background="var(--white)">
        <Grid>
          <WhoAreWeBox>
            <TitleAndDescription
              title=" Who are we?"
              className="TitleAndDescription"
            >
              <p>
                CreativeHub301 was born when illustrators Veronica De Giorgio
                ‘Artofvero’ and Beejaye Joseph ‘BespokeDiva’ began to discuss
                ways of showcasing their artwork to the general public without
                huge expense & affordable space to show their work. ‘We had no
                clue where to start, no one to advise us, on affordable spaces
                to showcase our work’. After a conversation with our friend and
                mentor about our frustration on this topic we came to the
                conclusion ‘we can’t be the only ones going through this’ and
                realised that there was a void in the market for a creative hub
                where professional creatives of all backgrounds, media & styles
                can showcase their work at an affordable prices to the general
                public.
              </p>
            </TitleAndDescription>
          </WhoAreWeBox>
          <WhatDoWeProvideBox>
            <TitleAndDescription
              title="What do we do provide?"
              className="TitleAndDescription"
            >
              <ul>
                <li>Platform to showcase work</li>
                <li>One to One Consultation</li>
                <li>Help with pricing </li>
                <li>Exhibition Space </li>
                <li>Social Media exposure</li>
                <li>Marketing</li>
                <li>Product photography - available upon request</li>
              </ul>
            </TitleAndDescription>
          </WhatDoWeProvideBox>
          <img src={ourStoryImg1} alt="our-story-image-1" />
          <img src={ourStoryImg2} alt="our-story-image-2" />
        </Grid>
      </PageSegment>
    </>
  )
}

export default OurStory
